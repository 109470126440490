export interface LoadingState {
  loading: {
    isLoading: boolean;
    isInitialLoading: boolean; // Add initial loading state
    message?: string;
  };
}

export interface LoadingActions {
  setLoading: (args: { isLoading: boolean; message?: string }) => void;
  setInitialLoading: (isInitialLoading: boolean) => void;
}

export const loadingSlice = (
  set: any,
): { data: LoadingState; actions: LoadingActions } => ({
  data: {
    loading: {
      isLoading: false,
      isInitialLoading: true,
      message: undefined,
    },
  },
  actions: {
    setLoading: ({
      isLoading,
      message,
    }: {
      isLoading: boolean;
      message?: string;
    }) => {
      set((state: any) => {
        state.data.loading.isLoading = isLoading;
        state.data.loading.message = message;
      });
    },
    setInitialLoading: (isInitialLoading: boolean) => {
      set((state: any) => {
        state.data.loading.isInitialLoading = isInitialLoading;
      });
    },
  },
});
