// store/slices/userSlice.ts
import { FormConfigType, initialCustomFormState } from "../types/form.types";

export interface UserState {
  userId: string | null;
  userDetails: {
    userName?: string | null;
    email?: string | null;
    role?: string;
  };
  userCustomFormData: FormConfigType;
}

export interface UserActions {
  setUserData: (data: { userId: string }) => void;
  setUserDetails: ({
    userName,
    email,
  }: {
    userName?: string | null;
    email?: string | null;
  }) => void;
  setUserCustomFormData: (data: FormConfigType) => void;
}

export const userSlice = (
  set: any,
): { data: UserState; actions: UserActions } => ({
  data: {
    userId: null,
    userDetails: {
      userName: null,
      email: null,
      role: "user",
    },
    userCustomFormData: initialCustomFormState,
  },
  actions: {
    setUserData: (data: { userId: string }) => {
      set((state: any) => {
        state.data.userId = data.userId;
      });
    },
    setUserDetails: ({ userName, email }) => {
      set((state: any) => {
        state.data.userDetails = {
          userName,
          email,
        };
      });
    },
    setUserCustomFormData: (formData: any) => {
      set((state: any) => {
        state.data.userCustomFormData = formData;
      });
    },
  },
});
