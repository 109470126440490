// store/slices/modalSlice.ts

import { ReactNode } from "react";

export interface ModalState {
  modal: {
    isOpen: boolean;
    content: ReactNode | null;
    modalName: string | null;
  };
}

export interface ModalActions {
  openModal: ({
    content,
    modalName,
  }: {
    content?: ReactNode;
    modalName?: string | null;
  }) => void;
  closeModal: () => void;
}

export const modalSlice = (
  set: any,
): { data: ModalState; actions: ModalActions } => ({
  data: {
    modal: {
      isOpen: false,
      content: null,
      modalName: null,
    },
  },
  actions: {
    openModal: ({
      content,
      modalName,
    }: {
      content?: ReactNode;
      modalName?: string | null;
    }) => {
      set((state: any) => {
        state.data.modal.isOpen = true;
        state.data.modal.content = content ?? null;
        state.data.modal.modalName = modalName ?? null;
      });
    },
    closeModal: () => {
      set((state: any) => {
        state.data.modal.isOpen = false;
        state.data.modal.content = null;
        state.data.modal.modalName = null;
      });
    },
  },
});
