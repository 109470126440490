// store/slices/uiSlice.ts

export interface UIState {
  sideBarVisible: boolean;
}

export interface UIActions {
  setSideBarVisible: (isVisible: boolean) => void;
}

export const uiSlice = (set: any): { data: UIState; actions: UIActions } => ({
  data: {
    sideBarVisible: true,
  },
  actions: {
    setSideBarVisible: (visible: boolean) => {
      set((state: any) => {
        state.data.sideBarVisible = visible;
      });
    },
  },
});
