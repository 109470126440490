// store/slices/toastSlice.ts
export interface ToastState {
  toast: {
    message: string | null;
    type: "success" | "error";
  };
}

export interface ToastActions {
  toast: (args: { message: string; type?: "success" | "error" }) => void;
}

export const toastSlice = (
  set: any,
): { data: ToastState; actions: ToastActions } => ({
  data: {
    toast: {
      message: null,
      type: "success",
    },
  },
  actions: {
    toast: ({
      message,
      type = "success",
    }: {
      message: string;
      type?: "success" | "error";
    }) => {
      set((state: any) => {
        state.data.toast.message = message;
        state.data.toast.type = type;
      });

      setTimeout(() => {
        set((state: any) => {
          state.data.toast.message = null;
        });
      }, 3000);
    },
  },
});
