// store/slices/dashboardSlice.ts

import { Card as NewCardType } from "@/app/(dashboard)/dashboard/test-dashboard/components/types";
import { DashboardData, DashboardCard } from "../types/store";

export interface NewDashboardData {
  name: string;
  cards: NewCardType[];
  dashboardId: string;
  type?: "template" | "shared";
}
export interface DashboardState {
  dashboardData: NewDashboardData[];
  selectedDashboard: string | null;
}

export interface DashboardActions {
  setSelectedDashboard: (id: string | null) => void;
  setDashboardData: (dashboardData: NewDashboardData[]) => void;
  // updateDashboardCard: ({
  //   dashboardName,
  //   cardIndex,
  //   newCardData,
  // }: {
  //   dashboardName: string;
  //   cardIndex: number;
  //   newCardData: Partial<DashboardCard>;
  // }) => void;
  // addDashboardCard: (dashboardID: string, newCard: DashboardCard) => void;
  updateCardType: ({
    dashboardId,
    cardId,
    newCardType,
  }: {
    dashboardId: string;
    cardId: string;
    newCardType: string;
  }) => void;
  removeDashboardCard: (dashboardName: string, cardIndex: number) => void;
  setDashboardCards: (dashboardName: string, newCards: DashboardCard[]) => void;
  addDashboard: (dashboardName: string) => string;
  updateDashboardName: (dashboardId: string, newName: string) => void;
  deleteDashboard: (dashboardId: string) => void;
}

// const initialDashboardData: DashboardData = {
// "Custom Dashboard": {
//   name: "Custom Dashboard",
//   cards: [
//     [null, null, null, null],
//     [null, null, null, null],
//     [null, null, null, null],
//   ],
// },
// };

// const initialSelectedDashboard = "Performance Dashboard";

export const dashboardSlice = (
  set: any,
): { data: DashboardState; actions: DashboardActions } => ({
  data: {
    dashboardData: [],
    selectedDashboard: "",
  },
  actions: {
    setSelectedDashboard: (id: string | null) => {
      set((state: any) => {
        state.data.selectedDashboard = id;
      });
    },
    setDashboardData: (dashboardData: NewDashboardData[]) => {
      set((state: any) => {
        state.data.dashboardData = dashboardData;
      });
    },
    // updateDashboardCard: ({ dashboardName, cardIndex, newCardData }) => {
    //   set((state: any) => {
    //     const dashboard = state.data.dashboardData[dashboardName];
    //     if (dashboard) {
    //       const card = dashboard.cards.find(
    //         (card: any) => card.index === cardIndex,
    //       );
    //       if (card) {
    //         Object.assign(card, newCardData);
    //       }
    //     }
    //   });
    // },
    // addDashboardCard: (dashboardId: string, newCard: DashboardCard) => {
    //   set((state: any) => {
    //     const dashboardIndex = state.data.dashboardData.findIndex(
    //       (dashboard: NewDashboardData) => dashboard.id === dashboardId,
    //     );

    //     if (dashboardIndex !== -1) {
    //       state.data.dashboardData[dashboardIndex].cards = newCard;
    //     }
    //   });
    // },
    updateCardType: ({ dashboardId, cardId, newCardType }) => {
      // alert("cardId : " + cardId + " : " + dashboardId + " : " + newCardType);
      set((state: any) => {
        const dashboard = state.data.dashboardData.find(
          (dashboard: NewDashboardData) =>
            dashboard.dashboardId === dashboardId,
        );

        if (dashboard) {
          const cardToUpdate = dashboard.cards.find(
            (card: any) => card.id === cardId,
          );
          console.log(cardToUpdate);
          if (cardToUpdate) {
            cardToUpdate.cardType = newCardType;
          }
        }
      });
    },

    removeDashboardCard: (dashboardName, cardIndex) => {
      set((state: any) => {
        const dashboard: any = state.data.dashboardData[dashboardName];
        if (dashboard) {
          dashboard.cards = dashboard.cards.filter(
            (card: any) => card.index !== cardIndex,
          );
        }
      });
    },
    setDashboardCards: (dashboardId: string, newCards: any) => {
      set((state: any) => {
        const dashboardIndex = state.data.dashboardData.findIndex(
          (dashboard: NewDashboardData) =>
            dashboard.dashboardId === dashboardId,
        );

        if (dashboardIndex !== -1) {
          state.data.dashboardData[dashboardIndex].cards = newCards;
        }
      });
    },
    addDashboard: (dashboardName: string) => {
      const id = crypto.randomUUID();
      set((state: any) => {
        state.data.dashboardData.push({
          dashboardId: id,
          name: dashboardName,
          cards: [],
        });
        state.data.selectedDashboard = id;
      });
      return id;
    },
    updateDashboardName: (dashboardId: string, newName: string) => {
      set((state: any) => {
        const dashboardIndex = state.data.dashboardData.findIndex(
          (dashboard: NewDashboardData) =>
            dashboard.dashboardId === dashboardId,
        );

        if (dashboardIndex !== -1) {
          state.data.dashboardData[dashboardIndex].name = newName;
        }
      });
    },

    deleteDashboard: (dashboardId: string) => {
      set((state: any) => {
        state.data.dashboardData = state.data.dashboardData.filter(
          (dashboard: NewDashboardData) =>
            dashboard.dashboardId !== dashboardId,
        );

        if (state.data.selectedDashboard === dashboardId) {
          state.data.selectedDashboard =
            state.data.dashboardData.length > 0
              ? state.data.dashboardData[0].id
              : null;
        }
      });
    },
  },
});
