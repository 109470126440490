// store/slices/colorSlice.ts

const defaultColor = "#336dff";

export interface ColorState {
  primaryColor: string;
}

export interface ColorActions {
  setPrimaryColor: (color: string) => void;
}

export const colorSlice = (
  set: any,
): { data: ColorState; actions: ColorActions } => ({
  data: {
    primaryColor: defaultColor,
  },
  actions: {
    setPrimaryColor: (color: string) => {
      set((state: any) => {
        state.data.primaryColor = color;
      });
      document.documentElement.style.setProperty("--color-primary", color);
      localStorage.setItem("primaryColor", color);
    },
  },
});
