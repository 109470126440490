import {
  MailchimpApiKey,
  MailchimpDataSchema,
  MailchimpProfile,
} from "../types/store";

// Define the actions interface
export interface MailchimpActions {
  setMailchimpProfile: (profile: MailchimpProfile) => void;
  addMailchimpApiKey: (keyName: string, apiKey: string, keyId?: string) => void;
  removeMailchimpApiKey: (keyId: string) => void;
  setMailchimpData: (data: MailchimpDataSchema[]) => void;
  addMailchimpData: (data: MailchimpDataSchema) => void;
  removeMailchimpData: (keyId: string) => void;
  updateMailchimpData: (
    keyId: string,
    data: Partial<MailchimpDataSchema>,
  ) => void;
  setSelectedMailchimpApiKey: (apiKeyId: string | null) => void;
  addProcessedData: (keyId: string, processedDataItem: any) => void;
}

export interface MailchimpState {
  mailchimpProfile: MailchimpProfile;
}

// Define initial state
const initialMailchimpProfile: MailchimpProfile = {
  mailchimpKeys: [],
  mailchimpData: [],
  selectedMailchimpKeyId: null,
};

// Create the Zustand slice
export const mailchimpSlice = (
  set: any,
): { data: MailchimpState; actions: MailchimpActions } => ({
  data: {
    mailchimpProfile: initialMailchimpProfile,
  },
  actions: {
    // Set the entire mailchimp profile
    setMailchimpProfile: (profile: MailchimpProfile) => {
      set((state: any) => {
        state.data.mailchimpProfile = profile || initialMailchimpProfile;
      });
    },

    // Add a new Mailchimp API Key
    addMailchimpApiKey: (keyName: string, apiKey: string, keyId?: string) => {
      set((state: any) => {
        // Ensure mailchimpProfile and mailchimpKeys exist
        if (!state.data.mailchimpProfile) {
          state.data.mailchimpProfile = initialMailchimpProfile;
        }
        if (!state.data.mailchimpProfile.mailchimpKeys) {
          state.data.mailchimpProfile.mailchimpKeys = [];
        }

        const newKey: MailchimpApiKey = {
          keyId: keyId || crypto.randomUUID(),
          keyName,
          mailchimpKey: apiKey,
          connected: false, // default value
        };
        state.data.mailchimpProfile.mailchimpKeys.push(newKey);
      });
    },

    // Remove an existing Mailchimp API Key
    removeMailchimpApiKey: (keyId: string) => {
      set((state: any) => {
        if (!state.data.mailchimpProfile?.mailchimpKeys) return;

        state.data.mailchimpProfile.mailchimpKeys =
          state.data.mailchimpProfile.mailchimpKeys.filter(
            (key: MailchimpApiKey) => key.keyId !== keyId,
          );

        if (state.data.mailchimpProfile.mailchimpData) {
          state.data.mailchimpProfile.mailchimpData =
            state.data.mailchimpProfile.mailchimpData.filter(
              (data: MailchimpDataSchema) => data.keyId !== keyId,
            );
        }

        if (state.data.mailchimpProfile.selectedMailchimpKeyId === keyId) {
          state.data.mailchimpProfile.selectedMailchimpKeyId = null;
        }
      });
    },

    setMailchimpData: (data: MailchimpDataSchema[]) => {
      set((state: any) => {
        if (!state.data.mailchimpProfile) {
          state.data.mailchimpProfile = initialMailchimpProfile;
        }
        state.data.mailchimpProfile.mailchimpData = data;
      });
    },

    // Add Mailchimp Data
    addMailchimpData: (data: MailchimpDataSchema) => {
      set((state: any) => {
        if (!state.data.mailchimpProfile) {
          state.data.mailchimpProfile = initialMailchimpProfile;
        }
        if (!state.data.mailchimpProfile.mailchimpData) {
          state.data.mailchimpProfile.mailchimpData = [];
        }

        const exists = state.data.mailchimpProfile.mailchimpData.some(
          (d: MailchimpDataSchema) => d.keyId === data.keyId,
        );
        if (!exists) {
          state.data.mailchimpProfile.mailchimpData.push(data);
        } else {
          console.warn(
            `MailchimpData with keyId ${data.keyId} already exists.`,
          );
        }
      });
    },

    // Remove Mailchimp Data by keyId
    removeMailchimpData: (keyId: string) => {
      set((state: any) => {
        if (!state.data.mailchimpProfile?.mailchimpData) return;

        const initialLength = state.data.mailchimpProfile.mailchimpData.length;
        state.data.mailchimpProfile.mailchimpData =
          state.data.mailchimpProfile.mailchimpData.filter(
            (data: MailchimpDataSchema) => data.keyId !== keyId,
          );
        if (
          state.data.mailchimpProfile.mailchimpData.length === initialLength
        ) {
          console.warn(`No MailchimpData found with keyId ${keyId} to remove.`);
        }
      });
    },

    // Update Mailchimp Data by keyId
    updateMailchimpData: (
      keyId: string,
      data: Partial<MailchimpDataSchema>,
    ) => {
      set((state: any) => {
        if (!state.data.mailchimpProfile?.mailchimpData) return;

        const index = state.data.mailchimpProfile.mailchimpData.findIndex(
          (d: MailchimpDataSchema) => d.keyId === keyId,
        );
        if (index !== -1) {
          state.data.mailchimpProfile.mailchimpData[index] = {
            ...state.data.mailchimpProfile.mailchimpData[index],
            ...data,
          };
        } else {
          console.warn(`No MailchimpData found with keyId ${keyId} to update.`);
        }
      });
    },

    // Set the selected Mailchimp API Key ID
    setSelectedMailchimpApiKey: (apiKeyId: string | null) => {
      set((state: any) => {
        if (!state.data.mailchimpProfile) {
          state.data.mailchimpProfile = initialMailchimpProfile;
        }

        if (apiKeyId === null) {
          state.data.mailchimpProfile.selectedMailchimpKeyId = null;
          return;
        }

        // Verify if the apiKeyId exists in mailchimpKeys
        const exists = state.data.mailchimpProfile.mailchimpKeys?.some(
          (key: MailchimpApiKey) => key.keyId === apiKeyId,
        );

        if (exists) {
          state.data.mailchimpProfile.selectedMailchimpKeyId = apiKeyId;
        } else {
          console.warn(
            `MailchimpApiKey with keyId ${apiKeyId} does not exist.`,
          );
        }
      });
    },

    // Add processed data to a specific MailchimpDataSchema
    addProcessedData: (keyId: string, processedDataItem: any) => {
      set((state: any) => {
        if (!state.data.mailchimpProfile?.mailchimpData) return;

        // Find the MailchimpDataSchema by keyId
        const index = state.data.mailchimpProfile.mailchimpData.findIndex(
          (d: MailchimpDataSchema) => d.keyId === keyId,
        );

        if (index !== -1) {
          const mailchimpData =
            state.data.mailchimpProfile.mailchimpData[index];

          // Ensure processedData array exists
          if (!mailchimpData.processedData) {
            mailchimpData.processedData = {};
          }

          // Add the new processedDataItem
          mailchimpData.processedData = processedDataItem;

          // Update the state with the modified mailchimpData
          state.data.mailchimpProfile.mailchimpData[index] = {
            ...mailchimpData,
          };
        } else {
          console.warn(`No MailchimpData found with keyId ${keyId} to update.`);
        }
      });
    },
  },
});
