// store/index.ts

import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { persist } from "zustand/middleware";
import { toastSlice } from "./slices/toastSlice";
import { modalSlice } from "./slices/modalSlice";
import { colorSlice } from "./slices/colorSlice";
import { userSlice } from "./slices/userSlice";
import { mailchimpSlice } from "./slices/mailchimpSlice";
import { dashboardSlice } from "./slices/dashboardSlice";
import { customReportSlice } from "./slices/customReportSlice";
import { uiSlice } from "./slices/uiSlice";
import { processedDataSlice } from "./slices/processedDataSlice";
import { ZustandStore } from "./types/store.types";
import { loadingSlice } from "./slices/loadingSlice";
import { formBuilderSlice } from "./slices/formBuilderSlice";

export const zustandStore = create<ZustandStore>()(
  persist(
    immer((set) => ({
      data: {
        _hydrated: false,
        ...toastSlice(set).data,
        ...modalSlice(set).data,
        ...colorSlice(set).data,
        ...userSlice(set).data,
        ...mailchimpSlice(set).data,
        ...dashboardSlice(set).data,
        ...customReportSlice(set).data,
        ...uiSlice(set).data,
        ...processedDataSlice(set).data,
        ...loadingSlice(set).data,
        ...formBuilderSlice(set).data,
      },
      actions: {
        ...toastSlice(set).actions,
        ...modalSlice(set).actions,
        ...colorSlice(set).actions,
        ...userSlice(set).actions,
        ...mailchimpSlice(set).actions,
        ...dashboardSlice(set).actions,
        ...customReportSlice(set).actions,
        ...uiSlice(set).actions,
        ...processedDataSlice(set).actions,
        ...loadingSlice(set).actions,
        ...formBuilderSlice(set).actions,
      },
    })),
    {
      name: "zustand-store",
      partialize: (state) => ({ data: state.data }),
      onRehydrateStorage: (state) => {
        if (state) {
          // state.data._hydrated = true;
        }
      },
    },
  ),
);
